var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"mfzqfozx drag",attrs:{"id":"mfzqfozx"}},[_c('div',[_c('div',{staticClass:"title-hang",attrs:{"fl":""}},[_vm._v(_vm._s(_vm.projectData.project)+"_SJCJ")]),_c('div',{staticClass:"param11-header-icon",attrs:{"fr":""},on:{"click":_vm.closeCompon}},[_c('img',{staticStyle:{"width":"100%","height":"auto"},attrs:{"src":require("@/assets/images/close.png")}})])]),_c('div',{staticClass:"titlelable"},[_vm._v("数据采集界面")]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"fl leftmain"},[_c('div',{staticClass:"row1"},[_vm._v(" 燃烧稳态")]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"width fl"},[_vm._v("ZQP_WTYZ")]),_c('div',{staticClass:"value fl color1",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.KHG.ZQP_WTYZ,
                  'ZQP_WTYZ',
                   'ZQP_WTYZ',
                  'KHG'
                 
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.KHG.ZQP_WTYZ))])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"width fl"},[_vm._v("YQO_WTYZ")]),_c('div',{staticClass:"value fl color1",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.KHG.YQO_WTYZ,
                  'YQO_WTYZ',
                   'YQO_WTYZ',
                  'KHG'
                 
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.KHG.YQO_WTYZ))])]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"width fl "},[_vm._v("RSXL_AVGYZ")]),_c('div',{staticClass:"value fl color1",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.KHG.RSXL_AVGYZ,
                  'RSXL_AVGYZ',
                   'RSXL_AVGYZ',
                  'KHG'
                 
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.KHG.RSXL_AVGYZ))])]),_vm._m(0),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"width fl"},[_vm._v("采集状态")]),_c('div',{staticClass:"valuex11 fl",style:({
          background: !_vm.infoList.KHG.WDZT_CZ ? '#2AFC30' : 'red' ,
        })})])]),_c('div',{staticClass:"fl rightmain"},[_c('div',{staticClass:"row1"},[_vm._v(" 给煤建模")]),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"width fl"},[_vm._v("GMJ_YZ")]),_c('div',{staticClass:"value fl color1",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.KHG.GFJC_YZ,
                  'GMJ_YZ',
                   'GFJC_YZ',
                  'KHG'
                 
                )}}},[_vm._v(" "+_vm._s(_vm.infoList.KHG.GFJC_YZ))])]),_vm._m(1),_c('div',{staticClass:"row2"},[_c('div',{staticClass:"width fl"},[_vm._v("采集状态")]),_c('div',{staticClass:"valuex11 fl",style:({
          background: !_vm.infoList.KHG.SDZT_CZ==1 ? '#2AFC30' : 'red' ,
        })})])])]),(_vm.isMshow)?_c('Manual',{key:_vm.isIndex,attrs:{"historyname":_vm.Manualname,"node":_vm.Manualnode,"Lkname":_vm.ManualAname,"titname":_vm.Manualtitname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose1}}):_vm._e(),_c('inputVal',{ref:"inputVal"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row2"},[_c('div',{staticClass:"width fl"},[_vm._v("强制采集开关")]),_c('div',{staticClass:"valuex12 fl "})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row2"},[_c('div',{staticClass:"width fl"},[_vm._v("强制采集开关")]),_c('div',{staticClass:"valuex12 fl "})])}]

export { render, staticRenderFns }