<template>
  <div class="mfzqfozx drag" id="mfzqfozx" v-draw>
    <!-- 标题部分 -->
    <div>
      <div class="title-hang" fl>{{ projectData.project }}_SJCJ</div>
      <div class="param11-header-icon" fr @click="closeCompon">
        <img
          src="~@/assets/images/close.png"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
    <div class="titlelable">数据采集界面</div>
    <div class="main">
      <div class="fl leftmain">
        <div class="row1">  燃烧稳态</div>
        <div class="row2"> 
          <div class="width fl">ZQP_WTYZ</div>
          <div class="value fl color1" @click="
                  toIpt(
                    infoList.KHG.ZQP_WTYZ,
                    'ZQP_WTYZ',
                     'ZQP_WTYZ',
                    'KHG',
                   
                  )
                "
        >
          {{ infoList.KHG.ZQP_WTYZ }}</div>
        </div>
          <div class="row2"> 
          <div class="width fl">YQO_WTYZ</div>
          <div class="value fl color1"
          @click="
                  toIpt(
                    infoList.KHG.YQO_WTYZ,
                    'YQO_WTYZ',
                     'YQO_WTYZ',
                    'KHG',
                   
                  )
                "
        >
          {{ infoList.KHG.YQO_WTYZ }}</div>
        </div>
          <div class="row2"> 
          <div class="width fl ">RSXL_AVGYZ</div>
          <div class="value fl color1"  @click="
                  toIpt(
                    infoList.KHG.RSXL_AVGYZ,
                    'RSXL_AVGYZ',
                     'RSXL_AVGYZ',
                    'KHG',
                   
                  )
                "
        >
          {{ infoList.KHG.RSXL_AVGYZ }}</div>
        </div>
           <div class="row2"> 
          <div class="width fl">强制采集开关</div>
          <div class="valuex12 fl "></div>
        </div>
           <div class="row2"> 
          <div class="width fl">采集状态</div>
          <div class="valuex11 fl" :style="{
            background: !infoList.KHG.WDZT_CZ ? '#2AFC30' : 'red' ,
          }"></div>
        </div>
      </div>
      <div class="fl rightmain">
        <div class="row1">  给煤建模</div>
        <div class="row2"> 
          <div class="width fl">GMJ_YZ</div>
          <div class="value fl color1" @click="
                  toIpt(
                    infoList.KHG.GFJC_YZ,
                    'GMJ_YZ',
                     'GFJC_YZ',
                    'KHG',
                   
                  )
                "
        >
          {{ infoList.KHG.GFJC_YZ }}</div>
        </div>

           <div class="row2"> 
          <div class="width fl">强制采集开关</div>
          <div class="valuex12 fl "></div>
        </div>
           <div class="row2"> 
          <div class="width fl">采集状态</div>
          <div class="valuex11 fl" :style="{
            background: !infoList.KHG.SDZT_CZ==1 ? '#2AFC30' : 'red' ,
          }"></div>
        </div>
      </div>

    </div>


    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>

     <Historical
      v-if="isHshow"
      @sendStatus="isClose1"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
  </div>
</template>
<script>
import Historical from "@/components/Historical";
import inputVal from "@/components/inputVal"; //输入框组件
import Manual from "@/components/Manual.vue"; //手操器组件
export default {
  name: "MfZQFOZX_ZX",
  components: {
    Manual,
    Historical,
    inputVal,
  },
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    }

  },
  data: () => {
    return {
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.8vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      isMshow: false,
      flag: 0,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
      spotArr: [],
      authInfo: [],
    };
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
  },
  computed: {},
  mounted() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    		// 双击事件
		Cclick(num,mark,node,name){
		// 组装数据
		 clearTimeout(this.grouptime);
		let spojobj = {
			num:num,
			mark:mark,
			node:node,
			name:name
		}
		this.spotArr.push(spojobj)
		// 数组去重
		let deWeightThree = () => {
			let map = new Map();
			for (let item of this.spotArr) {
				if (!map.has(item.name)) {
					this.$message.closeAll()
					map.set(item.name, item);
					this.$message.success('已添加');
				}else{
					this.$message.closeAll()
					this.$message.error('已添加到变量池里，切勿重复添加');
				}
			}
			return [...map.values()];
		}
		this.spotArr = deWeightThree();
		localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
	},
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
        	case 1:
					pathname = 'firstcontol'
					break
					case 2:
				    pathname = 'mfkfirstcontol'
					break
          	case 3:
				    pathname = 'mfRfirstcontol'
					break
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    closeCompon() {
      this.$emit("sendStatus", "MfZQFOZX_ZX", false);
    },
    isClose() {
      this.isMshow = false;
    },
    isClose1() {
      this.isHshow = false;
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    toCompon(key, name, name2, name3, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      switch (key) {
        case 0:
          return (
            (this.isMshow = true),
            (this.Manualname = name),
            (this.Manualnode = name2),
            (this.ManualAname = name3),
            (this.Manualtitname = titname)
          );
        case 1:
          return (
            (this.isFshow = true),
            (this.Firstcontrolname = name),
            (this.Firstcontrolnode = name2),
            (this.Firstcontroltitname = titname)
          );
        case 2:
          return (this.isHshow = true);
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.mfzqfozx {
  width: 40vw;
  height:78vh;
  //  background: #e9f7ff;
  background-image: url("~@/assets/images/jrl-param.png");
  background-size: 100% 100%;
  //  cursor:move;
  border-radius: 5px;

  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }
  .main-top {
    margin-left: 3.5vw;
    font-size: 1vw;
  }
  .left-span1 {
    width: 10vw;
    height: 30vh;
    // border: 1px saddlebrown solid;
  }

  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .title-hang {
    height: 5vh;
    width: 40vw;
    font-family: MicrosoftYaHei;
    font-size: 0.8vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
    // padding-top: 0.5vh;
    // margin-top:vh;
  }

  .title1 {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 0.8vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #ffffff;
    margin-top: 1vh;
    margin-left: 1vw;
  }
  .color1{
 color: #01ffba;
  }

  .title-hang {
    width: 100%;
    height: 6vh;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 1.2vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #0ef7ff;
  }
  .lable1 {
    font-family: PingFang-SC-Regular;
    font-size: 1.2vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2.5vh;
    letter-spacing: 0vh;
    color: #d5fffe;
    margin-left: 2.5vw;
    margin-top: 1vh;
  }
  .titlelable {
    width: 40vw;
    height: 4vh;
    font-family: PingFang-SC-Regular;
    font-size: 3vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0.3vw;
    color: #d5fffe;
    text-align: center;
    margin-top: 4vh;
  }
  .param11-header-icon {
    width: 2vw;
    height: 2vh;
    right: 1vw;
    margin-top: -5vh;
    cursor: pointer;
    margin-left: 37vw;
  }

  .bottonx {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 1vw;
    // margin-right: 0.1vw;
    color: #fff;
    margin: 1.5vh 0 0 7.8vw;
  }

  .main {
    margin-top: 4vh;
    color: #8aeaff;
    font-size: 1vw;
    width: 30vw;
    height: 52vh;
    	// background-color: #00e4ff20;
      border:1px solid #00e4ff40;
      margin:2vh 0 0 5vw;
    
  }
 
 .leftmain{
    width:15vw;
     height: 52vh;
      border-right:1px solid #00e4ff40;
 }
 .rightmain{
    width:14vw;
     height: 52vh;
      // border-right:1px solid #00e4ff40;
 }
  // .bottom-jg{
  //   margin:0 0 0 7.7vw;
  // }
  .valuex{
         width: 3vw;
           color: #01ffba;
  }
    .valuex11{
         width: 3vw;
         height:2.5vh;
        //  border:1px solid;
  }
    .valuex12{
         width: 3vw;
         height:2.5vh;
         background: #a7aaab;
        //  border:1px solid;
  }
  .row1{
    width:14vw;
    height: 2vh;
    line-height: 2vh;
    text-align: center;
    margin:10vh 0 2vh 0;
    font-size:1.2vw;
    color: #fff;

  }
  .width{
    width:8vw;
    height:3vh;
    line-height: 3vh;
     font-size:1vw;
      text-align: center;
  }
}
</style>